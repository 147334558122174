import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Banner from "../components/compare-banner"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"

const bannerContent = {
  height: "auto",
  roadmapHero: "roadmap",
  title: "We're always working to make Billsby even better with new features",
  text:
    "When you sign up for a Billsby account, you can vote in and influence our future roadmap, to make sure that the Billsby of tomorrow is even better suited to your needs than the Billsby of today, and we're adding things all the time.",
}

const TestStripe = () => {
  const stripePromise = loadStripe(
    "pk_test_51H4NevAP8I9LfBKlKPWrbPFtx1FGMBwxY6FpHI7c20PHBVja5BsGpycsDcgtyqG8u4phbECi1fbB2rjkQConcY9N00pdEgCHBi"
  )

  const [amount, setAmount] = React.useState(100)
  const [price, setPrice] = React.useState(99)

  const handleClick = async () => {
    try {
      const stripe = await stripePromise

      const session = await axios
        .get(
          "https://billsby-mailgun.onrender.com/api/stripe/create-checkout-session?amount=" +
            amount
        )
        .then(({ data }) => data)
        .catch(err => {
          throw err
        })

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      })

      if (result.error) console.log(result.error)
    } catch (e) {
      console.log(e)
    }
  }

  const handleOnChange = e => {
    let amount = parseInt(e.target.value)
    setAmount(amount)

    if (amount === 100) setPrice(99)

    if (amount === 200) setPrice(198)

    if (amount === 300) setPrice(297)

    if (amount === 400) setPrice(396)

    if (amount === 500) setPrice(495)
  }

  return (
    <Layout isHeaderTransparent>
      <>
        <SEO title="Test Stripe" />
        <Helmet>
          <title>Test stripe</title>
        </Helmet>

        <div className="roadmap">
          <Banner content={bannerContent} />
          <div className="section-swiper-tabs">
            <div className="container ">
              <h1 className="text-wrapper">Test Check out</h1>
              <section>
                <div className="product">
                  <div className="description">
                    <h3>Lifetime deal checkout</h3>
                    <p>
                      For every $99 one time fee, add another $100,000 per year
                      of revenue.
                    </p>

                    <select onBlur={handleOnChange} className="custom-input">
                      <option value="100">$100,000</option>
                      <option value="200">$200,000</option>
                      <option value="300">$300,000</option>
                      <option value="400">$400,000</option>
                      <option value="500">$500,000</option>
                    </select>
                    <p>
                      <small>
                        This is currently in drop down, click above to change
                        amount
                      </small>
                    </p>
                  </div>
                </div>

                <br />

                <button
                  type="button"
                  className="btn-orange"
                  role="link"
                  onClick={handleClick}
                >
                  Checkout for ${price}
                </button>
                <p>
                  <small>
                    No questions asked 14 day money back guarantee. New
                    customers only - incl. free trial customers but excl.
                    currently paying customers.
                  </small>
                </p>
              </section>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default TestStripe
